<template>
  <div>
    <ManageNews3 />
  </div>
</template>
<script>
import ManageNews3 from "@/components/News3/ManageNews3";
export default {
  components: {
    ManageNews3,
  },
  created() {},
};
</script>c